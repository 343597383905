import React from 'react'

import { API_URL, PIXEL_URL } from '@src/utils/api'
import { formChannelUrl } from '@src/utils/formChannelUrl'

type Props = Record<string, string | null> & {
  channel?: string
  video: string
  channel_id?: string
}

const livestreamConnectionOverlay =
  process.env.REACT_APP_ENV === 'production' ? 'true' : 'false'

const FWStoryblockBase: React.FC<Props> = ({
  channel,
  channel_id,
  video,
  ...rest
}) => {
  return (
    <fw-storyblock
      api_host={API_URL}
      api_host_tracking={PIXEL_URL}
      autoplay="true"
      channel={channel}
      channel_id={channel_id}
      loop="true"
      max_videos="1"
      pip="false"
      force_replay="true"
      share_template={`${formChannelUrl(
        channel
      )}?utm_source=business.firework.com&utm_medium=share`}
      show_ads="false"
      tap_to_watch="false"
      video={video}
      livestream_connection_overlay={livestreamConnectionOverlay}
      width="100%"
      {...rest}
    />
  )
}

export const FWStoryblock = React.memo(FWStoryblockBase)
