import { createAction, createSlice } from '@reduxjs/toolkit'
import api, { selfErrorHandlerRequest } from '@src/utils/api'
import { Dispatch } from 'redux'

export enum ShopMiniSyncStatus {
  Pending = 'pending',
  Finished = 'finished',
  Failed = 'failed'
}

export type ShopAppSetting = {
  id: string
  enabled: boolean
  status: ShopMiniSyncStatus
  business_store_id: string
  channel_id: string
  playlist_id: string
  page_type: string
  synced_at: string
  error_code?: string
}

type IProps = {
  shopAppEligible?: Record<string, boolean>
  shopAppSettingConfig?: Record<string, ShopAppSetting[]>
}

const initialState: IProps = {
  shopAppEligible: {},
  shopAppSettingConfig: {}
}

export const SHOP_MINIS_SLICE_NAME = 'shopMinis'

const slice = createSlice({
  name: SHOP_MINIS_SLICE_NAME,
  initialState,
  reducers: {
    fetchShopAppEligibilitySuccess(state, action) {
      const { businessStoreId, eligible } = action.payload
      state.shopAppEligible[businessStoreId] = eligible
    },
    fetchShopAppSettingsSuccess(state, action) {
      const { businessStoreId, shopAppSettings } = action.payload
      state.shopAppSettingConfig[businessStoreId] = shopAppSettings
    },
    syncShopAppSuccess(state, action) {
      const { shopAppSetting } = action.payload
      const businessStoreId = shopAppSetting.business_store_id
      let shopAppSettings = state.shopAppSettingConfig[businessStoreId]

      const existSetting = shopAppSettings?.find((item) => {
        return item.page_type === shopAppSetting.page_type
      })
      if (existSetting) {
        shopAppSettings = shopAppSettings.map((item) => {
          if (item.page_type === shopAppSetting.page_type) {
            return shopAppSetting
          }

          return item
        })
      } else {
        shopAppSettings = (shopAppSettings || []).concat([shopAppSetting])
      }
      state.shopAppSettingConfig[businessStoreId] = shopAppSettings
    }
  }
})

export default slice.reducer
export const {
  fetchShopAppEligibilitySuccess,
  fetchShopAppSettingsSuccess,
  syncShopAppSuccess
} = slice.actions

const fetchShopAppEligibilityRequest = createAction(
  `${SHOP_MINIS_SLICE_NAME}/fetchShopAppEligibilityRequest`
)
const fetchShopAppEligibilityFailure = createAction(
  `${SHOP_MINIS_SLICE_NAME}/fetchShopAppEligibilityFailure`
)

export const fetchShopAppEligibility = (params: {
  businessId: string
  businessStoreId: string
}) => {
  const { businessId, businessStoreId } = params

  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchShopAppEligibilityRequest())
      const response = await api.get(
        `/bus/${businessId}/shop_mini/shop_eligibility`,
        {
          params: {
            business_store_id: businessStoreId
          }
        }
      )

      const { eligible } = response.data
      dispatch(
        fetchShopAppEligibilitySuccess({
          businessStoreId,
          eligible
        })
      )

      return response.data
    } catch (error) {
      dispatch(fetchShopAppEligibilityFailure())

      return error
    }
  }
}

const syncShopAppRequest = createAction(
  `${SHOP_MINIS_SLICE_NAME}/syncShopAppRequest`
)
const syncShopAppFailure = createAction(
  `${SHOP_MINIS_SLICE_NAME}/syncShopAppFailure`
)

export const syncShopApp = (params: {
  businessId: string
  businessStoreId: string
  channelId: string
  pageType: string
  playlistId?: string
  enabled: boolean
}) => {
  const {
    businessId,
    businessStoreId,
    channelId,
    pageType,
    playlistId,
    enabled
  } = params

  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(syncShopAppRequest())
      const response = await api.post(
        `/bus/${businessId}/shop_mini/sync_shop_app_page`,
        {
          business_store_id: businessStoreId,
          channel_id: channelId,
          playlist_id: playlistId,
          enabled,
          page_type: pageType
        },
        selfErrorHandlerRequest()
      )

      const res = response.data as ShopAppSetting

      dispatch(
        syncShopAppSuccess({
          shopAppSetting: res
        })
      )

      return response
    } catch (error) {
      dispatch(syncShopAppFailure())

      return error
    }
  }
}

const fetchShopAppSettingsRequest = createAction(
  `${SHOP_MINIS_SLICE_NAME}/fetchShopAppSettingsRequest`
)
const fetchShopAppSettingsFailure = createAction(
  `${SHOP_MINIS_SLICE_NAME}/fetchShopAppSettingsFailure`
)

export const fetchShopAppSettings = (params: {
  businessId: string
  businessStoreId: string
}) => {
  const { businessId, businessStoreId } = params

  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchShopAppSettingsRequest())
      const response = await api.get(
        `/bus/${businessId}/shop_mini/shop_app_settings`,
        {
          params: {
            business_store_id: businessStoreId
          }
        }
      )

      const { shop_app_settings: shopAppSettings } = response.data
      dispatch(
        fetchShopAppSettingsSuccess({
          businessStoreId,
          shopAppSettings
        })
      )

      return response.data
    } catch (error) {
      dispatch(fetchShopAppSettingsFailure())

      return error
    }
  }
}

const publishToShopAppRequest = createAction(
  `${SHOP_MINIS_SLICE_NAME}/publishToShopAppRequest`
)
const publishToShopAppFailure = createAction(
  `${SHOP_MINIS_SLICE_NAME}/publishToShopAppFailure`
)

export function publishToShopApp(
  businessId: string,
  channelId: string,
  playlistId: string,
  businessStoreId: string,
  location: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(publishToShopAppRequest())
      const response = await api.post(
        `/bus/${businessId}/business_stores/shop_mini/set_entry_point`,
        {
          business_store_id: businessStoreId,
          channel_id: channelId,
          location: location,
          playlist_id: playlistId
        }
      )

      return response
    } catch (error) {
      dispatch(publishToShopAppFailure())

      return error
    }
  }
}
